'use strict';

const toastHelpers = require('core/components/toast');

var sizeRecommender = {};

function registerUser(data, url) {
    var $modal = $('#workfitterEmailModal');
    var $error = $modal.find('.wf-login-mismatch');

    $error.text('').hide();
    data.currentURL = window.location.href;

    $.ajax({
        type: 'GET',
        url: url,
        data: data,
        success: function(response) {
            if (response.loggedInSessionExpired) {
                // If user is no longer logged in, show them the guest email field entry w/ error message
                $error.text(response.expiredMessage).show();
                $modal.modal('show');
                $.spinner().stop();
            } else if (response.redirectURL) {
                // redirect user to the given URL, if one is supplied
                window.location.href = response.redirectURL;
            } else {
                // otherwise, throw general error
                $modal.modal('hide');
                toastHelpers.methods.show('danger', response.generalError, true);
                $.spinner().stop();
            }
        },
        error: function(error) {
            console.error(error);
            $.spinner().stop();
        }
    });
}

sizeRecommender.init = function () {
    $('body').on('click', '.wf-find-size', function () {
        var $btn = $(this);
        var $container = $('.workfitter-size-recommender');
        var url = $btn.data('url');
        var isLoggedIn = $btn.data('isloggedin');
        var data = {
            isLoggedIn: isLoggedIn
        };

        if (isLoggedIn) {
            $container.spinner().start();
            registerUser(data, url);
        } else {
            // Show guest user email input
            $('#workfitterEmailModal').modal('show');
        }
    });

    $('body').on('submit', '#workfitterEmailModal .wf-modal-form', function (e) {
        e.preventDefault();
        var $modal = $('#workfitterEmailModal');
        var $form = $modal.find('form');
        var url = $form.attr('action');
        var userID = $form.find('.wf-guest-email').val();
        var data = {
            isLoggedIn: false,
            userID: userID
        };

        if ($form[0].checkValidity()) {
            $modal.spinner().start();
            registerUser(data, url);
        }
    });

    $('body').on('click', '#workfitterEmailModal .wf-modal-submit', function (e) {
        e.preventDefault();
        $('.wf-modal-form').trigger('submit');
    });
};

module.exports = sizeRecommender;
