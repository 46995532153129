'use strict';
var shippingHelpers = require('base/checkout/shipping');

module.exports = {
    updateShippingMethods: function() {
		var $shippingMethodBlock = $('.shipping-method-block');
		if($('#isResidentialAddress').prop('checked')==true){
			$shippingMethodBlock.addClass('residential-address-selected');
		} else {
			$shippingMethodBlock.addClass('business-address-selected');
		};
		$('body').on('checkout:updateCheckoutView', function (e, data) {
			data.order.shipping.forEach(function(shipping) {
				shippingHelpers.methods.updateShippingInformation(
					shipping,
					data.order,
					data.customer,
					data.options
				);
			});
		});

		$('body').on('shipping:updateShippingMethods', function (e, data) {
			if (data && data.shipping && data.shipping.applicableShippingMethods) {
                var hasBusinessMethods = false;
				data.shipping.applicableShippingMethods.forEach(function(method) {
                    if(method.isBusinessMethod) {
                        hasBusinessMethods = true;
                    }
					$('[value='+method.ID+']' ).parent().parent().addClass(method.isBusinessMethod ? 'business-shipping-method' : 'residential-shipping-method');
					$('[value='+method.ID+']' ).parent().parent().next().addClass(method.isBusinessMethod ? 'business-shipping-method' : 'residential-shipping-method');
				});

                hasBusinessMethods ?  $('.business-method-row').removeClass('d-none') :  $('.business-method-row').addClass('d-none');
			}
			// Set default state based on selected method type
			if (data && data.shipping && data.shipping.selectedShippingMethod && data.shipping.selectedShippingMethod.isBusinessMethod) {
				$('#isBusinessAddress').prop('checked', true).change();
			} else {
				$('#isResidentialAddress').prop('checked', true).change();
			}

        });
		$('body').on('change', '.shipping-method-residential-business input', function(e) {
			var methodAddressType = $(this).val();
			var selectedMethodType = $('input:checked', '.shipping-method-list').parent().parent().hasClass('business-shipping-method') ? 'business' : 'residential';
			if (methodAddressType === 'business') {
				$shippingMethodBlock.removeClass('residential-address-selected');
				$shippingMethodBlock.addClass('business-address-selected');

				// Select buisness option if business method has been chosen
				if (selectedMethodType !== 'business') {
					$('.shipping-method-list .business-shipping-method').first().find('input').prop('checked', true).change();
				}

				// Add class if not business method is unavailable, display a message
				if ($shippingMethodBlock.find('.business-shipping-method').length === 0) {
					$shippingMethodBlock.addClass('business-methods-unavailable');
				} else {
					$shippingMethodBlock.removeClass('business-methods-unavailable');
				}

			} else { // Residential Type
				$shippingMethodBlock.removeClass('business-address-selected');
				$shippingMethodBlock.addClass('residential-address-selected');

				if (selectedMethodType !== 'residential') {
					$('.shipping-method-list .residential-shipping-method').first().find('input').prop('checked', true).change();
				}

				// Add class if not residential method is unavailable, display a message
				if ($shippingMethodBlock.find('.residential-shipping-method').length === 0) {
					$shippingMethodBlock.addClass('residential-methods-unavailable');
				} else {
					$shippingMethodBlock.removeClass('residential-methods-unavailable');
				}

			}
		});
	}
};
